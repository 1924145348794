import Form from "./Form"

export default class Input {
    constructor(private parentFieldSetElement: HTMLFieldSetElement, public elementInFieldSet: HTMLInputElement | HTMLTextAreaElement, private parentForm: Form) {
        this.elementInFieldSet.addEventListener("focus", this.inputFocused.bind(this));
        this.elementInFieldSet.addEventListener("blur", this.inputBlurred.bind(this));
        this.elementInFieldSet.addEventListener("change", this.inputChange.bind(this));
        this.checkIfInputValueIsComplete();
    }

    // input events
    inputFocused() {
        this.parentFieldSetElement.classList.add("focused");
    };

    inputBlurred() {
        this.parentFieldSetElement.classList.remove("focused");
    };

    inputChange() {
        this.parentForm.setFormStatus();
    };


    inputIsEmpty() {
        this.parentFieldSetElement.classList.remove("completed");
    };
    inputIsComplete() {
        this.parentFieldSetElement.classList.add("completed");
    };
    checkIfInputValueIsComplete() {
        if(this.elementInFieldSet.value.length > 0) {
            this.inputIsComplete();
        } else {
            this.inputIsEmpty();
        }
    };
    checkIf_isRequired_and_elementIsComplete() {
        if(this.elementInFieldSet.hasAttribute("required")) {
            if(this.elementInFieldSet.value.length > 0) {
                if (this.elementInFieldSet.hasAttribute("type") && this.elementInFieldSet.getAttribute("type") === "email") {
                    return Input.checkMail(this.elementInFieldSet.value);
                } else {
                    return true;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    public static checkMail(mail: string) {
        const reg = new RegExp('^[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*$', 'i');

        return(reg.test(mail));
    }

}
