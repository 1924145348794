import Form from "./Form"
import MailApp from "./MailApp"

export default class ContactContainer {
    listOfForms: Form[] = [];

    constructor(private contact_container_element: HTMLElement, private InterventionContactApp: MailApp) {
        this.disableForm();

        //get forms in page
        const forms_in_this_container = this.contact_container_element.querySelectorAll('.element-form') as NodeListOf<HTMLFormElement>;
        for(let j = 0; j < forms_in_this_container.length; j++) {
            this.listOfForms.push( new Form(forms_in_this_container[j]) );
        }

    };

    isActive() {
        return this.contact_container_element.dataset.active === "true";
    };

    disableForm() {
        this.contact_container_element.dataset.active = "false";
    };
}
