import ContactContainer from "./ContactContainer"

export default class MailApp {
    listOfContactContainers: ContactContainer[] = [];

    constructor () {
        const mailApplication = document.querySelectorAll(".app-mail") as NodeListOf<HTMLElement>;
        for(let i = 0; i < mailApplication.length; i++) {
            this.listOfContactContainers.push( new ContactContainer(mailApplication[i], this) );
        }
    };
}
