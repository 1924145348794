import Input from "./Input"

export default class Form {
    listOfInputObject: Input[] = [];
    submit = this.formElement.querySelector('input[type="submit"]') as HTMLInputElement;

    constructor(private formElement: HTMLFormElement) {
        this.submit.addEventListener("click", this.onClickToSubmit.bind(this) );
        const fieldSets = formElement.querySelectorAll("fieldset");
        for(let i = 0; i < fieldSets.length; i++) {
            const elementInFieldSet = fieldSets[i].querySelector("input") || fieldSets[i].querySelector("textarea") as HTMLInputElement | HTMLTextAreaElement;
            this.listOfInputObject.push( new Input(fieldSets[i], elementInFieldSet, this) );
        }
    };

    checkAllInputValueStatus() {
        for(let i = 0; i <  this.listOfInputObject.length; i++) {
            this.listOfInputObject[i].checkIfInputValueIsComplete();
        }
    };

    allRequiredElementAreComplete() {
        let allIsComplete = true;
        for(let i = 0; i < this.listOfInputObject.length; i++) {
            if (! this.listOfInputObject[i].checkIf_isRequired_and_elementIsComplete()) {
                allIsComplete = false;
            }
        }
        return allIsComplete;
    };

    setSubmitStatus() {
        if( this.allRequiredElementAreComplete() ) {
            this.submit.setAttribute("form-complete", "true");
        } else {
            this.submit.setAttribute("form-complete", "false");
        }
    };

    setFormStatus() {
        this.checkAllInputValueStatus();
        this.setSubmitStatus();
    };


    onClickToSubmit(event: MouseEvent) {
        if (this.allRequiredElementAreComplete()) {
            this.sendData();
            this.formElement.classList.add("send");
            event.preventDefault();
        }
    };

    sendData() {
        // this.formElement.classList.add("sending")
        console.log("send data");
        const data: any = {};
        for (let i = 0; i < this.listOfInputObject.length; i++) {
            const inputElement = this.listOfInputObject[i].elementInFieldSet;
            data[inputElement.dataset.name as string] = inputElement.value;
        }

        console.log(data);

        this.sendJSONFormData(data, this.formElement,function(JSONResponse: any) {
            console.log(JSONResponse);
        });
    };

    sendJSONFormData(data: any, formElement: HTMLFormElement, callback: any) {
        const req = new XMLHttpRequest();

        req.addEventListener("readystatechange", function () {
            if (this.readyState === XMLHttpRequest.DONE) {
                if (this.status === 202) {
                    // callback( JSON.parse(this.responseText) );
                    console.log("202")
                  setTimeout(() => {
                    formElement.classList.add("sending")
                  }, 750);
                } else {
                    console.log("Status de la réponse: %d (%s)", this.status, this.statusText);
                    callback({
                        "valid": false,
                        "error": "status !== 202: status = " + this.status
                    });
                }
            }
        });

        req.open('POST', '/', true);
        req.setRequestHeader("Content-Type", "text/plain");
        req.send(JSON.stringify(data));
    };
}
