export default class MenuTopMinimize {
    classNameToMinifyMenu = "minimize"
    menuToMinimize = document.querySelector("#top-menu")
    previousWindowYScrollPosition = window.pageYOffset
    menuIsMinimize = false

    constructor() {
        window.addEventListener("scroll", () => {
            const currentWindowYScrollPosition = window.pageYOffset

            if(currentWindowYScrollPosition > this.previousWindowYScrollPosition && !this.menuIsMinimize) {
                this.menuIsMinimize = true
                this.menuToMinimize.classList.add(this.classNameToMinifyMenu)
            } else if(currentWindowYScrollPosition < this.previousWindowYScrollPosition && this.menuIsMinimize) {
                this.menuIsMinimize = false
                this.menuToMinimize.classList.remove(this.classNameToMinifyMenu)
            }

            this.previousWindowYScrollPosition = currentWindowYScrollPosition
        })
    }
}